const tokens = {
    ftm: {
      symbol: 'FTM',
      projectLink: 'https://fantom.foundation/',
    },

    wftm: {
      symbol: 'WFTM',
      address: {
        56: '0x1ffd0b47127fdd4097e54521c9e2c7f0d66aafc5',
        97: '0x51a1ceb83b83f1985a81c295d1ff28afef186e02',
        0xfa2: '0x077faB8F7f79178F6718BDfdFfd5c3b8D787AED5',
      },
      decimals: 18,
      projectLink: 'https://wmatic.org/',
    },

    syrup: {
      symbol: 'SYRUP',
      address: {
        56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
        97: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
        0xfa2: '0xa46e02f635444fd1ae20c5dD2A32e632b44b23e3',
      },
      decimals: 18,
      projectLink: 'https://pancakeswap.finance/',
    },

    NGT: {
      symbol: 'NGT',
      address: {
        56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
        97: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
        0xfa2: '0x8eDECBBb95936A65543AfBCA51Ac52f6c13626A2',
      },
      decimals: 18,
      projectLink: 'https://pancakeswap.finance/',
    },

    NGT9: {
      symbol: 'NGT9',
      address: {
        56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
        97: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
        0xfa2: '0x7a3CA2B09bA3A401598b264d1745b0667B60f41a',
      },
      decimals: 18,
      projectLink: 'https://pancakeswap.finance/',
    },

    NGT10: {
      symbol: 'NGT10',
      address: {
        56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
        97: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
        0xfa2: '0x7fC52F67708F4Af620A6f645aA5D35efa1ADCF6b',
      },
      decimals: 18,
      projectLink: 'https://pancakeswap.finance/',
    },
    busd: {
      symbol: 'busd',
      address: {
        56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
        97: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
        0xfa2: '0x558d111e950EC429d871BF0192289bD6bc97Bf69',
      },
      decimals: 18,
      projectLink: 'https://pancakeswap.finance/',
    },
    eth: {
      symbol: 'eth',
      address: {
        56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
        97: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
        0xfa2: '0x077faB8F7f79178F6718BDfdFfd5c3b8D787AED5',
      },
      decimals: 18,
      projectLink: 'https://pancakeswap.finance/',
    },
    usdt:{
      symbol: 'USDT',
      address: {
        56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
        97: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
        0xfa2: '0xE3D54a0988aE01Fbe896255Ccc74c17db4Ab7D24',
      },
      decimals: 18,
      projectLink: 'https://pancakeswap.finance/',
    }
   
    
  }
  
  export default tokens
  